'use client'
import { useState, useEffect } from 'react'
import { useTranslations } from 'next-intl'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { CSSTransition } from 'react-transition-group'

import Icon from '@/app/components/Ui/Icon'
import Button from '@/app/components/Ui/Button'
import FormCheckbox from '@/app/components/Ui/FormCheckbox'

import { submitFormSubscribe } from '@/app/api/form-builder/apiFormBuilder'

import styles from './SubscribeForm.module.scss'

let timeout

export default function SubscribeForm({ checkBoxPersonalDataText }) {
  const t = useTranslations()
  const [isShowSuccess, setIsShowSuccess] = useState(false)
  const { register, handleSubmit, reset, watch, setValue, formState: { isSubmitting, errors, isDirty } } = useForm()
  const formData = watch()
  const localStorageKey = "form-subscribe-form"

  const onSubmit = async (data) => {
    try {
      setIsShowSuccess(false)
      clearTimeout(timeout)
      await submitFormSubscribe(data)
      reset()
      window.localStorage.removeItem(localStorageKey)
      setIsShowSuccess(true)
      timeout = setTimeout(() => {
        setIsShowSuccess(false)
      }, 5000)
    } catch (e) {
      console.warn(e, '-> SubscribeForm')
    }
  }

  useEffect(() => {
    if (isDirty) {
      const data = {}

      for (const key in formData) {
        if (formData.hasOwnProperty(key) && typeof formData[key] !== "undefined") {
          data[key] = key === "phone" && formData[key] === "+38 (0__) ___ __ __"
            ? undefined
            : formData[key]
        }
      }

      window.localStorage.setItem(localStorageKey, JSON.stringify(data))
    }
  }, [formData, isDirty])

  useEffect(() => {
    const item = window.localStorage.getItem(localStorageKey)
    const storeForm = item ? JSON.parse(item) : null

    if (storeForm) {
      for (const key in storeForm) {
        if (storeForm.hasOwnProperty(key) && typeof storeForm[key] !== "undefined") {
          setValue(key, storeForm[key])
        }
      }
    }
  }, [setValue])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles['subscribe-form']}
    >
      <div className={styles['subscribe-form_label']}>
        {t('subscribeForm.subscribeText')}
      </div>
      <div className={classNames(styles['subscribe-form_control'], 'd-f ai-c')}>
        <input
          className={classNames(
            styles['subscribe-form_input'],
            {
              'b-error': errors?.email
            },
            'input'
          )}
          placeholder='Email'
          {...register('email', {
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: ''
            }
          })}
        />
        <Button
          isLoading={isSubmitting}
          extraOption='only-icon'
        >
          <Icon
            name='arrow'
            width={16}
            height={16}
            viewBox="0 0 16 16"
          />
        </Button>
      </div>
      <FormCheckbox
        register={register}
        name={'privacyPolicy'}
        validation={{
          required: true
        }}
        errors={errors}
        text={checkBoxPersonalDataText || 'Заповнюючи форму я даю згоду на обробку персональних даних'}
      />
      <CSSTransition
        in={isShowSuccess}
        timeout={300}
        unmountOnExit
        classNames="fade"
      >
        <div
          className={classNames(
            styles['subscribe-form_success'],
            'd-f ai-c'
          )}
        >
          <Icon
            name={'check-circle'}
            width={20}
            height={20}
            viewBox="0 0 20 20"
          />
          <span>{t('form.successMsg')}</span>
        </div>
      </CSSTransition>
    </form>
  )
}

SubscribeForm.propTypes = {
  checkBoxPersonalDataText: PropTypes.string
}
