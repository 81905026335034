'use client'
import { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './FooterBlock.module.scss'

export default function FooterBlock({ title, children }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div
      className={classNames(
        styles['footer-block'],
        {
          [styles['b-open']]: isOpen
        }
      )}
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={classNames(styles['footer-block_title'], 'd-f ai-c jc-sb')}
      >
        {title}
        <button className={styles['footer-block_btn']} />
      </div>
      <div className={styles['footer-block_content']}>
        {children}
      </div>
    </div>
  )
}

FooterBlock.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
}
