'use client'
import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import HeaderMobileMenu from '@/app/components/Header/HeaderMobileMenu'
import Icon from '@/app/components/Ui/Icon'
import Link from '@/app/components/Ui/Link'

import styles from './HeaderMobile.module.scss'

export default function HeaderMobile({
  phone,
  logo,
  dropdownMenu,
  bottomMenu,
  menu
}) {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const mergeMenuDropdownMenu = useMemo(() => {
    if (dropdownMenu) {
      return menu.map((item) => {
        return {
          ...item,
          children: [
            ...(item?.children || []),
            {
              id: dropdownMenu?.id,
              name: dropdownMenu?.mobileName,
              icon: dropdownMenu.icon,
              link: '',
              children: dropdownMenu?.items || []
            }
          ]
        }
      })
    }
    return menu
  }, [menu, dropdownMenu])

  const handlerResize = () => {
    if (window.innerWidth > 1024) {
      document.body.style.overflow = 'auto'
      setIsOpenMenu(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handlerResize)
    return () => {
      window.removeEventListener('resize', handlerResize)
    }
  }, [])

  const onToggleOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu)
    document.body.style.overflow = !isOpenMenu ? 'hidden' : 'auto'
  }

  const onCloseMenu = () => {
    if (isOpenMenu) {
      setIsOpenMenu(false)
      document.body.style.overflow = 'auto'
    }
  }

  return (
    <div className={classNames(styles['header-mobile'], { [styles['b-open']]: isOpenMenu })}>
      <div className={classNames(styles['header-mobile_wrapper'], 'wr d-f ai-c jc-sb')}>
        <Link
          className={'logo b-image-filter'}
          href="/"
          onClick={onCloseMenu}
        >
          <img src={logo} alt='ingo' width='83' height='19' />
        </Link>
        <a
          className={'d-f ai-c header-link b-with-icon'}
          href={`tel:${phone}`}
          target="_blank"
        >
          <Icon
            name="phone"
            width={14}
            height={14}
            className={'header-link_icon'}
          />
          {phone}
        </a>
        <button
          onClick={onToggleOpenMenu}
          className={classNames(
            styles['header-mobile_btn'],
            {
              [styles['b-open']]: isOpenMenu
            }
          )} />
      </div>
      <CSSTransition
        in={isOpenMenu}
        timeout={400}
        unmountOnExit
        classNames="fade"
      >
        <div className={styles['header-mobile_content']}>
          <div className={styles['header-mobile_inner']}>
            <HeaderMobileMenu
              items={mergeMenuDropdownMenu}
              closeMenu={onCloseMenu}
              handlerClick={onToggleOpenMenu}
              bottomMenu={bottomMenu}
            />
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

HeaderMobile.propTypes = {
  phone: PropTypes.string,
  logo: PropTypes.string,
  dropdownMenu: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
    mobileName: PropTypes.string,
    icon: PropTypes.string,
    items: PropTypes.array
  }),
  bottomMenu: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    name: PropTypes.string
  })),
  menu: PropTypes.array
}
