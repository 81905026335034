'use client'
import { useEffect, useRef } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next-intl/client'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Icon from '@/app/components/Ui/Icon'

import styles from './HeaderSearch.module.scss'

export default function HeaderSearch({ handlerClose, handlerAfterSubmit }) {
  const t = useTranslations('form')
  const formRef = useRef()
  const router = useRouter()
  const { register, handleSubmit, formState: { errors } } = useForm()

  const onCloseForm = () => {
    if (typeof handlerClose === 'function') {
      handlerClose()
    }
  }

  const onSubmit = ({ searchText }) => {
    router.push(`/search?search=${searchText}`)
    if (typeof handlerAfterSubmit === 'function') {
      handlerAfterSubmit()
    }
  }

  const onClickOutside = (e) => {
    if (formRef.current && !formRef.current.contains(e.target)) {
      onCloseForm()
    }
  }

  useEffect(() => {
    document.addEventListener("click", onClickOutside)
    return () => {
      document.removeEventListener("click", onClickOutside)
    }
  }, [])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(styles['header-search'])}
      ref={formRef}
    >
      <button type='submit' className={classNames(styles['header-search_btn'], styles['b-search'], 'd-f ai-c jc-c')}>
        <Icon
          name={'search'}
          width={20}
          height={20}
          viewBox="0 0 24 24"
        />
      </button>
      <input
        {...register('searchText', {
          minLength: {
            value: 4,
            message: t('minLength')
          }
        })}
        autoFocus={true}
        type='text'
        className={styles['header-search_input']}
        placeholder={t('search')}
      />
      {errors?.['searchText']?.message && (
        <div className='form_error-text'>
          {errors?.['searchText']?.message}
        </div>
      )}
      <button
        type='button'
        onClick={onCloseForm}
        className={classNames(styles['header-search_btn'], styles['b-close'], 'd-f ai-c jc-c')}
      >
        <Icon
          name={'close'}
          width={20}
          height={20}
          viewBox="0 0 21 21"
        />
      </button>
    </form>
  )
}

HeaderSearch.propTypes = {
  handlerAfterSubmit: PropTypes.func,
  handlerClose: PropTypes.func
}
