import(/* webpackMode: "eager" */ "/app/app/assets/images/brights-logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/CookieNotice/CookieNotice.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/ExternalScripts/ExternalScripts.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Footer/ButtonScrollTop/ButtonScrollTop.js");
;
import(/* webpackMode: "eager" */ "/app/app/components/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Footer/FooterBlock/FooterBlock.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Footer/SubscribeForm/SubscribeForm.js");
;
import(/* webpackMode: "eager" */ "/app/app/components/Header/HeaderDesktop/HeaderDesktop.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Header/Header.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Footer/FooterContact/FooterContact.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Header/HeaderMenu/HeaderMenu.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Header/HeaderMobile/HeaderMobile.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Header/LangDropdown/LangDropdown.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Ui/Link/Link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/VisualImpairments/VisualImpairments.js");
;
import(/* webpackMode: "eager", webpackExports: ["LocalesProvider"] */ "/app/app/context/LocalesContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/src/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
