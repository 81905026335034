'use client'
import { useEffect, useState } from 'react'
import { usePathname } from 'next-intl/client'
import classNames from 'classnames'
import Icon from '@/app/components/Ui/Icon'

import styles from './ButtonScrollTop.module.scss'

export default function ButtonScrollTop() {
  const pathname = usePathname()
  const [isShowBtn, setIsShowBtn] = useState(false)

  const onToggleShowBtn = () => {
    const footer = document.getElementById('footer')
    if (footer) {
      setIsShowBtn(
        window.pageYOffset !== 0 && (window.pageYOffset + window.innerHeight >= footer.offsetTop) ||
        window.pageYOffset >= window.innerHeight * 2
      )
    }
  }

  const onScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    onToggleShowBtn()
  }, [pathname])

  useEffect(() => {
    window.addEventListener('scroll', onToggleShowBtn)
    return () => {
      window.removeEventListener('scroll', onToggleShowBtn)
    }
  }, [])

  return (
    <button
      type='button'
      onClick={onScrollTop}
      className={classNames(
        styles['button-scroll-top'],
        'd-f ai-c jc-c button-scroll-top',
        {
          [styles['b-show']]: isShowBtn
        }
      )}
    >
      <Icon
        name='arrow-top'
        width={32}
        height={32}
        viewBox="0 0 32 32"
      />
    </button>
  )
}
