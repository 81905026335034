'use client'
import { useMemo } from 'react'
import { useParams } from 'next/navigation'
import { useRouter, usePathname } from 'next-intl/client'
import classNames from 'classnames'
import useLocales from '@/app/hooks/useLocales'

import { i18n } from '@/i18n-config'

import styles from './LangDropdown.module.scss'

export default function LangDropdown() {
  const params = useParams()
  const router = useRouter()
  const pathName = usePathname()
  const { availableLocales } = useLocales()
  const activeLocaleName = useMemo(() => {
    return i18n.locales.find(i => i.hrefLang === params.locale)?.name || params.locale
  }, [params.locale])

  const redirectedPathName = (locale, slug) => {
    if (!slug) return '/'
    return locale !== i18n.defaultLocale ? `/${locale}${slug}` : slug
  }

  return (
    <div className={classNames(styles['lang-dropdown'])}>
      <ul className={classNames(styles['lang-dropdown_content'], 'd-f ai-fs fd-c')}>
        <li className={styles['lang-dropdown_item']}>
          <a
            href={redirectedPathName(params.locale, pathName)}
            className={styles['lang-dropdown_link']}
          >
            {activeLocaleName}
          </a>
        </li>
        {availableLocales.map((item, index) => (
          <li
            key={item.slug + index}
            className={styles['lang-dropdown_item']}
          >
            <a
              href={redirectedPathName(item.hrefLang, item.slug)}
              className={styles['lang-dropdown_link']}
            >
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
