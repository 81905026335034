import { useState, cloneElement, useEffect, useMemo } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import HeaderSearch from '@/app/components/Header/HeaderSearch'
import Link from '@/app/components/Ui/Link'
import Icon from '@/app/components/Ui/Icon'

import styles from './MultiLevelMenu.module.scss'

const LinkContent = ({ data }) => {
  return (
    <>
      {data.icon && (
        <span className={classNames(styles['multi-level-menu_icon'], 'd-f ai-c jc-c b-image-filter')}>
          <img src={data.icon} alt='icon' />
        </span>
      )}
      <span>
        {data.name}
      </span>
      {data.label &&
        <span className={styles['multi-level-menu_label']}>
          {data.label}
        </span>
      }
      {Array.isArray(data.children) && data.children.length !== 0 && (
        <span className={styles['multi-level-menu_arrow']}>
          <Icon
            name="arrow-2"
            width={16}
            height={16}
          />
        </span>
      )}
    </>
  )
}

export default function MultiLevelMenu({
  items,
  getLevel,
  closeMenu
}) {
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [level, setLevel] = useState(0)
  const [itemsStack, setItemsStack] = useState([{
    id: 0,
    children: items
  }])
  const [move, changeMove] = useState('next')
  const [id, setId] = useState(null)
  const bottomMenu = useMemo(() => {
    return itemsStack?.[level]?.bottomMenu || []
  }, [itemsStack, level])
  const itemsToShow = useMemo(() => {
    return itemsStack?.[level]?.children || []
  }, [itemsStack, level])
  const hasOneChildren = useMemo(() => {
    if (Array.isArray(itemsToShow) && itemsToShow.length) {
      return itemsToShow.some((item) => Array.isArray(item.children) && item.children.length)
    }
    return false
  }, [itemsToShow])

  const resetLevel = () => {
    setLevel(0)
    if (typeof getLevel === 'function') {
      getLevel(0)
    }
  }

  const handlerLevel = (number) => {
    const newLevel = level + number
    setLevel(newLevel)
    if (typeof getLevel === 'function') {
      getLevel(newLevel)
    }
  }

  useEffect(() => {
    setItemsStack([{
      id: 0,
      children: items
    }])
    changeMove('next')
    setId(null)
    resetLevel()
  }, [items])

  const changeMenuItems = (
    newItemsStack,
    updatedMove,
    updatedId
  ) => {
    setItemsStack(newItemsStack)
    changeMove(updatedMove)
    setId(updatedId)
  }

  const moveToNext = (targetItem) => {
    if (targetItem?.children && targetItem.children.length > 0) {
      const newItems = itemsToShow.find(
        (item) => item.id === targetItem.id
      )
      if (
        newItems !== undefined &&
        Array.isArray(newItems.children) &&
        newItems.children.length > 0
      ) {
        changeMenuItems(
          [
            ...itemsStack,
            {
              ...newItems,
              children: [
                {
                  link: 'back',
                  name: targetItem.name
                },
                ...newItems.children
              ]
            }
          ],
          'next',
          Math.random().toString(36).substr(2, 9)
        )
        handlerLevel(1)
      }
    } else if (closeMenu) {
      closeMenu(targetItem)
    }
  }


  const moveToPrevious = () => {
    const newItemsStack = [...itemsStack]
    const newItemsToShow = newItemsStack.pop()
    if (newItemsToShow !== undefined) {
      changeMenuItems(
        newItemsStack,
        'prev',
        Math.random().toString(36).substr(2, 9)
      )
      handlerLevel(-1)
    }
  }

  const childFactoryCreator = (classNames) => (child) => cloneElement(child, { classNames })

  const onSubmitSearch = () => {
    setIsOpenSearch(false)
    if (typeof closeMenu === 'function') {
      closeMenu()
    }
  }

  if (isOpenSearch) {
    return (
      <HeaderSearch
        handlerAfterSubmit={onSubmitSearch}
        handlerClose={() => setIsOpenSearch(false)}
      />
    )
  }

  return (
    <TransitionGroup
      className={styles['multi-level-menu']}
      childFactory={childFactoryCreator(
        move === 'next' ? 'slideIn' : 'slideOut'
      )}
    >
      <CSSTransition timeout={300} key={id}>
        <div>
          <ul className={styles['multi-level-menu_list']}>
            {itemsToShow.map((item, index) => {
              if (item.link === 'back') {
                return (
                  <li key={index} className={classNames(styles['multi-level-menu_back'], 'd-f ai-c jc-sb')}>
                    <div
                      onClick={() => moveToPrevious(item)}
                      className={classNames(styles['multi-level-menu_link'], 'd-f ai-c')}
                    >
                      <span className={classNames(styles['multi-level-menu_icon'], 'd-f ai-c jc-c')}>
                        <Icon
                          name="arrow-left-2"
                          className="b-image-filter"
                          width={16}
                          height={16}
                        />
                      </span>
                      {item.name}
                    </div>
                    <button
                      className={'header_search-btn d-f ai-c jc-c'}
                      type='button'
                      onClick={() => setIsOpenSearch(true)}
                    >
                      <Icon
                        name={'search'}
                        width={25}
                        height={25}
                        viewBox="0 0 24 24"
                      />
                    </button>
                  </li>
                )
              }
              return (
                <li
                  key={index}
                  className={classNames(
                    styles['multi-level-menu_item'],
                    {
                      [styles['b-with-children']]: hasOneChildren
                    }
                  )}
                >
                  {item.link ? (
                    <Link
                      href={item.link}
                      isOpenNewTab={item.isOpenNewTab}
                      onClick={() => moveToNext(item)}
                      className={classNames(
                        styles['multi-level-menu_link'],
                        'd-f ai-c',
                        {
                          'b-has-children': Array.isArray(item.children) && item.children.length
                        }
                      )}
                    >
                      <LinkContent data={item} />
                    </Link>
                  ) : (
                    <div
                      onClick={() => moveToNext(item)}
                      className={classNames(
                        styles['multi-level-menu_link'],
                        'd-f ai-c',
                        {
                          'b-has-children': Array.isArray(item.children) && item.children.length
                        }
                      )}
                    >
                      <LinkContent data={item} />
                    </div>
                  )}
                </li>
              )
            })}
          </ul>
          {Array.isArray(bottomMenu) && bottomMenu.length !== 0 && (
            <ul className={classNames(styles['multi-level-menu_list'], styles['b-bottom-menu'])}>
              {bottomMenu.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={styles['multi-level-menu_item']}
                  >
                    <Link
                      href={item.link || '/'}
                      onClick={() => moveToNext(item)}
                      isOpenNewTab={item.isOpenNewTab}
                      className={classNames(styles['multi-level-menu_link'], 'd-f ai-c')}
                    >
                      <LinkContent data={item} />
                    </Link>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  )
}

MultiLevelMenu.propTypes = {
  closeMenu: PropTypes.func,
  getLevel: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
    link: PropTypes.string,
    isOpenNewTab: PropTypes.any,
    label: PropTypes.string,
    children: PropTypes.array,
    bottomMenu: PropTypes.array
  }))
}