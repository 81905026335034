'use client'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Link from '@/app/components/Ui/Link'

import styles from './Banner.module.scss'

export default function Banner ({ data, onClickLink }) {
  const {
    label,
    title,
    text = '',
    isOpenNewTab,
    linkText,
    background,
    image,
    link = ''
  } = data
  return (
    <div
      className={classNames(
        styles['banner'],
        'd-f ai-c fd-c jc-fs',
        styles[`bg-${background || 'blue'}`],
        {
          [styles['b-has-text']]: Boolean(text)
        }
      )}
    >
      <Link
        href={link}
        onClick={onClickLink}
        isOpenNewTab={isOpenNewTab}
        className={styles['banner_over-link']}
      />
      <div className={styles['banner_label']}>
        {label}
      </div>
      <div className={styles['banner_title']}>
        {title}
      </div>
      {text && (
        <div className={styles['banner_text']}>
          {text}
        </div>
      )}
      <Link
        href={link}
        onClick={onClickLink}
        isOpenNewTab={isOpenNewTab}
        className={classNames(styles['banner_btn'], 'btn bg-white')}
      >
        {linkText}
      </Link>
      {image && (
        <div className={classNames(styles['banner_image'], 'd-f ai-fe jc-fe')}>
          <img
            src={image}
            alt='banner image'
          />
        </div>
      )}
    </div>
  )
}

Banner.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    linkText: PropTypes.string,
    isOpenNewTab: PropTypes.any,
    background: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.string
  }),
  onClickLink: PropTypes.func
}
