'use client'
import { useState, useEffect } from 'react'
import { useTranslations } from "next-intl"
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '@/app/components/Ui/Icon'
import VisualImpairmentsSettings from './VisualImpairmentsSettings'

import { LOCAL_KEY } from '@/app/constants/visualImpairments'

import styles from './VisualImpairments.module.scss'

export default function VisualImpairments({ isTooltipText }) {
  const t = useTranslations('visualImpairments')
  const [isOpenSettings, setIsOpenSetting] = useState(false)

  useEffect(() => {
    const getLocalSettings = localStorage.getItem(LOCAL_KEY)
    if (getLocalSettings) {
      const localSettings = JSON.parse(getLocalSettings)
      for (const [key, value] of Object.entries(localSettings)) {
        document.documentElement.setAttribute(`data-${key}`, value)
      }
    }
  }, [])

  return (
    <div className={classNames(styles['visual-impairments'], 'visual-impairments')}>
      <button
        type='button'
        onClick={() => setIsOpenSetting(!isOpenSettings)}
        className={classNames(
          styles['visual-impairments_btn'],
          'visual-impairments_btn d-if ai-c jc-fs',
          {
            [styles['is-tooltip']]: isTooltipText
          }
        )}
      >
        <Icon
          name="eye"
          width={20}
          height={20}
          viewBox="0 0 20 20"
        />
        <span className={styles['visual-impairments_text']}>
          {t('text')}
        </span>
      </button>
      <CSSTransition
        in={isOpenSettings}
        timeout={300}
        unmountOnExit
        classNames={'fade'}
      >
        <VisualImpairmentsSettings
          onClose={() => setIsOpenSetting(false)}
        />
      </CSSTransition>
    </div>
  )
}

VisualImpairments.propTypes = {
  text: PropTypes.string,
  isTooltipText: PropTypes.bool
}