'use client'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { setCookie } from 'cookies-next'
import { useTranslations } from 'next-intl'

import styles from './CookieNotice.module.scss'

let timeout

export default function CookieNotice({ message }) {
  const t = useTranslations('cookieNotice')
  const [isShowMessage, setIsShowMessage] = useState(false)

  const onSaveCookie = () => {
    setCookie('allow-cookie-usage', 'true', {
      maxAge: 7 * 24 * 60 * 60 * 1000
    })
    setIsShowMessage(false)
    document.body.classList.remove('b-show-cookie-modal')
  }

  useEffect(() => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setIsShowMessage(true)
      document.body.classList.add('b-show-cookie-modal')
    }, 3000)
    return () => {
      document.body.classList.remove('b-show-cookie-modal')
      clearTimeout(timeout)
    }
  }, [])

  return (
    <div
      className={classNames(
        styles['cookie-notice'], 'd-f ai-c',
        {
          [styles['b-active']]: isShowMessage
        }
      )}
    >
      {!!message && (
        <div
          className={classNames(styles['cookie-notice_text'], 'rich-text')}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <button
        className='btn'
        type='button'
        onClick={onSaveCookie}
      >
        {t('buttonText')}
      </button>
    </div>
  )
}

CookieNotice.propTypes = {
  message: PropTypes.string
}
