'use client'
import { useParams } from 'next/navigation'
import { useMemo } from 'react'

let prevMenu
let prevLocal

const checkItemUrl = (item, url) => {
  const isValidItem = item.link.endsWith(url) && !item.ignoreRootCategory
  if (Array.isArray(item.children)) {
    return isValidItem || item.children.some(b => checkItemUrl(b, url))
  }
  return isValidItem
}

export default function useActiveMenu({ pathname, items }) {
  const params = useParams()

  const findActiveMenu = useMemo(() => {
    if (Array.isArray(items) && items.length) {
      const defaultMenu = items[0] || []
      if (prevLocal !== params?.locale) {
        prevLocal = params.locale
        prevMenu = null
      }
      const url = pathname.split('/').slice(1).join('/')
      if (url) {
        const findMenu = items.find((item) => checkItemUrl(item, url)) || prevMenu || defaultMenu
        prevMenu = findMenu
        return findMenu
      }
      prevMenu = defaultMenu
      return defaultMenu
    }
    return null
  }, [pathname, items, params?.locale])

  return findActiveMenu
}
