'use client'
import { useMemo, useState, useEffect, useRef } from 'react'
import { useTranslations } from 'next-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '@/app/components/Ui/Icon'
import Button from '@/app/components/Ui/Button'

import { FONT_SIZE, COLOR_SCHEME, FILTER_IMAGE } from '@/app/constants/visualImpairments'

import styles from './VisualImpairmentsSettings.module.scss'

const LOCAL_KEY = 'visualImpairmentsSettings'

export default function VisualImpairmentsSettings({ onClose }) {
  const t = useTranslations('visualImpairments')
  const visualImpairmentsRef = useRef()
  const [selectedSetting, setSelectedSetting] = useState({
    [FONT_SIZE]: 'default',
    [COLOR_SCHEME]: 'default',
    [FILTER_IMAGE]: 'default'
  })
  const isShowResetButton = useMemo(() => {
    return Object.values(selectedSetting).some((item) => item !== 'default')
  }, [selectedSetting])
  const settings = useMemo(() => {
    return [
      {
        label: t('fontSizeLabel'),
        type: FONT_SIZE,
        options: [
          {
            value: 'default',
            label: 'Aa'
          },
          {
            value: 'md',
            label: 'Aa'
          },
          {
            value: 'lg',
            label: 'Aa'
          }
        ]
      },
      {
        label: t('colorSchemeLabel'),
        type: COLOR_SCHEME,
        options: [
          {
            value: 'default',
            label: t('colorSchemeDefault'),
          },
          {
            value: 'whiteBlack',
            textIcon: 'A',
            label: t('colorSchemeWhiteBlack')
          },
          {
            value: 'blackWhite',
            textIcon: 'A',
            label: t('colorSchemeBlackWhite')
          },
          {
            value: 'blue',
            textIcon: 'A',
            label: t('colorSchemeBlue')
          },
        ]
      },
      {
        label: t('filterImageLabel'),
        type: FILTER_IMAGE,
        options: [
          {
            value: 'default',
            label: t('filterImageDefault'),
          },
          {
            value: 'blackWhite',
            label: t('filterImageBlackWhite')
          },
          {
            value: 'hide',
            label: t('filterImageHide')
          }
        ]
      }
    ]
  }, [])

  const onSelectOption = (type, value) => {
    const _selectedSetting = {
      ...selectedSetting,
      [type]: value
    }
    setSelectedSetting(_selectedSetting)
    localStorage.setItem(LOCAL_KEY, JSON.stringify(_selectedSetting))
    if (value === 'default') {
      document.documentElement.removeAttribute(`data-${type}`)
      return
    }
    document.documentElement.setAttribute(`data-${type}`, value)
  }

  const onResetSetting = (e) => {
    setSelectedSetting({
      [FONT_SIZE]: 'default',
      [COLOR_SCHEME]: 'default',
      [FILTER_IMAGE]: 'default'
    })
    localStorage.removeItem(LOCAL_KEY)
    document.documentElement.removeAttribute(`data-${FONT_SIZE}`)
    document.documentElement.removeAttribute(`data-${COLOR_SCHEME}`)
    document.documentElement.removeAttribute(`data-${FILTER_IMAGE}`)
  }

  useEffect(() => {
    const getLocalSettings = localStorage.getItem(LOCAL_KEY)
    if (getLocalSettings) {
      const localSettings = JSON.parse(getLocalSettings)
      for (const [key, value] of Object.entries(localSettings)) {
        document.documentElement.setAttribute(`data-${key}`, value)
      }
      setSelectedSetting(localSettings)
    }
  }, [])

  const onClickOutside = (e) => {
    if (visualImpairmentsRef.current && !visualImpairmentsRef.current.contains(e.target)) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener("click", onClickOutside)
    return () => {
      document.removeEventListener("click", onClickOutside)
    }
  }, [isShowResetButton])

  return (
    <div
      ref={visualImpairmentsRef}
      className={classNames(
        styles['visual-impairments-settings'],
        'visual-impairments-settings d-f ai-fs jc-fs fd-c'
      )}
    >
      <div className={classNames(styles['visual-impairments-settings_wrapper'], 'visual-impairments-settings_wrapper')}>
        <button
          onClick={onClose}
          className={styles['visual-impairments-settings_btn-close']}
          type='button'
        />
        <div className={classNames(styles['visual-impairments-settings_top'], 'visual-impairments-settings_top d-f ai-c fw-w')}>
          <div className={classNames(styles['visual-impairments-settings_title'], 'h5')}>
            {t('title')}
          </div>
          {isShowResetButton && (
            <button
              className={classNames(styles['visual-impairments-settings_reset-btn'], 'd-f ai-c')}
              type='button'
              onClick={onResetSetting}
            >
              <Icon
                name={'reset'}
                width={16}
                height={16}
                viewBox="0 0 16 16"
              />
              {t('reset')}
            </button>
          )}
        </div>
        <div className={styles['visual-impairments-settings_list']}>
          {settings.map((item) => (
            <div
              key={item.type}
              className={classNames(
                styles['visual-impairments-settings_block'],
                styles[`b-${item.type}`],
                'd-f ai-c'
              )}
            >
              <div className={classNames(styles['visual-impairments-settings_name'], 'h6')}>
                {item.label}
              </div>
              <div className={classNames(styles['visual-impairments-settings_options'], 'd-f ai-c')}>
                {item.options.map((option) => (
                  <button
                    key={`${item.type}-${option.value}`}
                    onClick={() => onSelectOption(item.type, option.value)}
                    className={classNames(
                      styles['visual-impairments-settings_option'],
                      'd-f ai-c',
                      {
                        [styles['has-icon']]: option.textIcon,
                        [styles['b-active']]: selectedSetting[item.type] === option.value
                      }
                    )}
                    type='button'
                  >
                    {option.label}
                    {option.textIcon && (
                      <span className={styles['visual-impairments-settings_option-icon']}>
                        {option.textIcon}
                      </span>
                    )}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={classNames(styles['visual-impairments-settings_bottom'], 'visual-impairments-settings_bottom ai-s jc-fe')}>
        {isShowResetButton && (
          <Button onClick={onResetSetting} extraOption='with-icon' type='button'>
            <Icon
              name={'reset'}
              width={16}
              height={16}
              viewBox="0 0 16 16"
            />
            {t('reset')}
          </Button>
        )}
        <Button theme='bordered' extraOption='with-icon' onClick={onClose} type='button'>
          <Icon
            name={'close'}
            width={12}
            height={12}
            viewBox="0 0 21 21"
          />
          {t('close')}
        </Button>
      </div>
    </div>
  )
}

VisualImpairmentsSettings.propTypes = {
  onClose: PropTypes.func
}