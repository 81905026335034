'use client'
import { useState, useRef, useEffect } from 'react'
import { usePathname } from 'next-intl/client'
import { useParams } from 'next/navigation'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import useActiveMenu from '@/app/hooks/useActiveMenu'

import DropdownMenu from '@/app/components/Header/HeaderMenu/DropdownMenu'
import HeaderSearch from '@/app/components/Header/HeaderSearch'
import Link from '@/app/components/Ui/Link'
import Icon from '@/app/components/Ui/Icon'

import styles from './HeaderMenu.module.scss'

export default function HeaderMenu({ items = [] }) {
  const pathname = usePathname()
  const params = useParams()
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [isOpenActions, setIsOpenActions] = useState(true)
  const [activeMenuKey, setActiveMenuKey] = useState(undefined)
  const [isSticky, setIsSticky] = useState(false)
  const [menuHeight, setMenuHeight] = useState(73.59)
  const activeTab = useActiveMenu({ pathname, items })
  const menuRef = useRef()

  const handlerScroll = () => {
    const paginationBySections = document.getElementById('pagination-by-sections')
    if (paginationBySections) {
      setIsSticky(false)
      return
    }
    const offsetTop = window.pageYOffset
    let menuOffsetTop = menuRef.current.offsetTop
    if (!isSticky) {
      menuOffsetTop = menuOffsetTop + menuHeight
    }
    setIsSticky(offsetTop >= menuOffsetTop)
  }

  const handlerResize = () => {
    setMenuHeight(menuRef.current.offsetHeight)
  }

  useEffect(() => {
    handlerScroll()
    handlerResize()
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll)
    window.addEventListener('resize', handlerResize)
    return () => {
      window.removeEventListener('scroll', handlerScroll)
      window.removeEventListener('resize', handlerResize)
    }
  }, [isSticky])

  const onActiveMenuKey = (index) => {
    setActiveMenuKey(index)
  }

  const onCloseSubmenu = () => {
    setActiveMenuKey(undefined)
  }

  const onClickLink = () => {
    onCloseSubmenu()
    setIsOpenSearch()
  }

  return (
    <>
      <div
        ref={menuRef}
        style={{
          minHeight: `${menuHeight}px`
        }}
        className={styles['header-menu_container']}
        id='header-menu'
      >
        <div className={classNames(
          styles['header-menu_wrapper'],
          {
            [styles['is-sticky']]: isSticky
          }
        )}>
          <div className='wr'>
            <div className={classNames(styles['header-menu_content'], 'd-f ai-c jc-sb')}>
              <nav className={classNames(styles['header-menu'], 'd-f ai-c')}>
                <ul className={classNames(styles['header-menu_tabs'], 'd-f ai-c')}>
                  {items.map((item, index) => (
                    <li
                      key={index}
                      className={classNames(
                        styles['header-menu_tabs-item'],
                        {
                          [styles['b-focus']]: activeTab?.name === item.name
                        }
                      )}
                    >
                      <Link
                        className={styles['header-menu_link']}
                        href={item.link}
                        isOpenNewTab={item.isOpenNewTab}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
                {Array.isArray(activeTab?.children) && activeTab.children.length !== 0 && (
                  <ul className={classNames(styles['header-menu_list'], 'd-f ai-c')} onMouseLeave={onCloseSubmenu}>
                    {activeTab.children.map((item, index) => (
                      <li
                        key={params?.locale + index}
                        onMouseEnter={() => onActiveMenuKey(index)}
                        className={classNames(
                          styles['header-menu_item'],
                          {
                            [styles['b-has-children']]: item.children && item.children?.length,
                            [styles['b-focus']]: activeMenuKey === index
                          }
                        )}
                      >
                        {item.link ? (
                          <Link
                            className={styles['header-menu_link']}
                            onClick={onClickLink}
                            href={item.link}
                            isOpenNewTab={item.isOpenNewTab}
                          >
                            {item.name}
                          </Link>
                        ) : (
                          <div className={styles['header-menu_link']}>
                            {item.name}
                          </div>
                        )}
                      </li>
                    ))}
                    {activeTab.children.map((item, index) => item?.children?.length !== 0 && (
                      <CSSTransition
                        key={params?.locale + index}
                        in={Boolean(activeMenuKey === index)}
                        timeout={300}
                        unmountOnExit
                        classNames="fade"
                      >
                        <DropdownMenu
                          items={item?.children}
                          handlerClick={onClickLink}
                          view={item?.view}
                          banner={item?.banner}
                        />
                      </CSSTransition>
                    ))}
                  </ul>
                )}
                <CSSTransition
                  in={isOpenActions}
                  timeout={200}
                  unmountOnExit
                  onExited={() => {
                    setIsOpenSearch(true)
                  }}
                  classNames={'fade'}
                >
                  <div className={classNames(styles['header-menu_action'], 'd-f ai-c')}>
                    {activeTab?.whiteBtn && (
                      <Link href={activeTab.whiteBtn.link} className={classNames('btn bg-white b-with-icon')}>
                        <Icon
                          name="bolt"
                          width={11}
                          height={15}
                          viewBox="0 0 11 15"
                        />
                        {activeTab.whiteBtn.name}
                      </Link>
                    )}
                    {activeTab?.blueBtn && (
                      <Link href={activeTab.blueBtn.link} className={'btn'}>
                        {activeTab.blueBtn.name}
                      </Link>
                    )}
                    <button
                      className={'header_search-btn d-f ai-c jc-c'}
                      type='button'
                      onClick={() => setIsOpenActions(false)}
                    >
                      <Icon
                        name={'search'}
                        width={25}
                        height={25}
                        viewBox="0 0 24 24"
                      />
                    </button>
                  </div>
                </CSSTransition>
              </nav>
              <CSSTransition
                in={isOpenSearch}
                timeout={200}
                unmountOnExit
                classNames={isOpenSearch ? 'slideInShort' : 'slideOutShort'}
                onExited={() => {
                  setIsOpenActions(true)
                }}
              >
                <HeaderSearch
                  handlerClose={() => setIsOpenSearch(false)}
                  handlerAfterSubmit={() => setIsOpenSearch(false)}
                />
              </CSSTransition>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

HeaderMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
    isOpenNewTab: PropTypes.any,
    view: PropTypes.oneOf(['column', 'row']),
    banner: PropTypes.object,
    whiteBtn: PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string
    }),
    blueBtn: PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string
    }),
    children: PropTypes.array
  }))
}
