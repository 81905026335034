'use client'
import useEventInitializer from '@/app/hooks/useEventInitializer'

export default function ExternalScripts () {
  const promiseLoadScript = (src) => new Promise(resolve => {
    const script = document.createElement('script')
    script.src = src
    script.onload = resolve
    document.body.appendChild(script)
  })

  const loadScripts = async () => {
    await Promise.all([
      promiseLoadScript(
        "https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"
      ),
      promiseLoadScript(
        "https://webtracking-v01.creatio.com/JS/track-cookies.js"
      ),
      promiseLoadScript(
        "https://webtracking-v01.bpmonline.com/JS/create-object.js"
      ),
    ])
  }

  useEventInitializer(() => {
    loadScripts()
  })

  return null
}

