'use client'
import { useState } from 'react'
import { usePathname } from 'next-intl/client'
import { useTranslations } from 'next-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import useActiveMenu from '@/app/hooks/useActiveMenu'
import HeaderSearch from '@/app/components/Header/HeaderSearch'
import Link from '@/app/components/Ui/Link'
import Icon from '@/app/components/Ui/Icon'
import LangDropdown from '@/app/components/Header/LangDropdown'
import VisualImpairmentsSettings from '@/app/components/VisualImpairments/VisualImpairmentsSettings'
import MultiLevelMenu from '@/app/components/Header/HeaderMobileMenu/MultiLevelMenu'

import styles from './HeaderMobileMenu.module.scss'

export default function HeaderMobileMenu({
  items = [],
  closeMenu,
  bottomMenu,
  handlerClick
}) {
  const pathname = usePathname()
  const t = useTranslations('visualImpairments')
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [isOpenSettings, setIsOpenSetting] = useState(false)
  const [level, setLevel] = useState(0)
  const activeTab = useActiveMenu({ pathname, items })

  const onCloseMenu = (item) => {
    if (typeof handlerClick === 'function' && !(Array.isArray(item?.children) && item.children.length !== 0)) {
      handlerClick()
    }
  }

  const onOpenSearch = () => {
    setIsOpenSearch(true)
  }

  const onSubmitSearch = () => {
    setIsOpenSearch(false)
    if (typeof handlerClick === 'function') {
      handlerClick()
    }
  }

  const onToggleOpenSetting = () => {
    setIsOpenSetting(!isOpenSettings)
  }

  if (isOpenSearch) {
    return (
      <HeaderSearch
        handlerAfterSubmit={onSubmitSearch}
        handlerClose={() => setIsOpenSearch(false)}
      />
    )
  }

  if (isOpenSettings) {
    return (
      <div className={classNames(styles['mobile-menu_setting'])}>
        <div className={classNames(styles['mobile-menu_setting-top'], 'd-f ai-c jc-sb')}>
          <div
            onClick={onToggleOpenSetting}
            className={classNames(styles['mobile-menu_setting-back'], 'd-f ai-c')}
          >
            <span className={classNames(styles['multi-level-menu_icon'], 'd-f ai-c jc-c')}>
              <Icon
                name="arrow-left-2"
                className="b-image-filter"
                width={16}
                height={16}
              />
            </span>
            {t('title')}
          </div>
          <button
            className={'header_search-btn d-f ai-c jc-c'}
            type='button'
            onClick={onOpenSearch}
          >
            <Icon
              name={'search'}
              width={25}
              height={25}
              viewBox="0 0 24 24"
            />
          </button>
        </div>
        <VisualImpairmentsSettings
          onClose={onToggleOpenSetting}
        />
      </div>
    )
  }

  return (
    <nav className={classNames(styles['mobile-menu'])}>
      {level === 0 &&
        <div className={classNames(styles['mobile-menu_top'], 'd-f ai-c jc-sb')}>
          <ul className={classNames(styles['mobile-menu_tabs'], 'd-f ai-c fw-w')}>
            {items.map((item, index) => (
              <li
                key={index}
                className={classNames(
                  styles['mobile-menu_tabs-item'],
                  {
                    [styles['b-active']]: activeTab?.name === item.name
                  }
                )}
              >
                <Link
                  className={styles['mobile-menu_tabs-link']}
                  href={item.link}
                  isOpenNewTab={item.isOpenNewTab}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
          <button
            className={'header_search-btn d-f ai-c jc-c'}
            type='button'
            onClick={onOpenSearch}
          >
            <Icon
              name={'search'}
              width={25}
              height={25}
              viewBox="0 0 24 24"
            />
          </button>
        </div>
      }

      <MultiLevelMenu
        items={activeTab?.children}
        getLevel={setLevel}
        closeMenu={onCloseMenu}
      />

      {level === 0 &&
        <>
          {Array.isArray(bottomMenu) && bottomMenu.length > 0 && (
            <ul className={styles['mobile-menu_bottom-menu']}>
              {bottomMenu.map((item, index) => (
                <li key={index} className={styles['mobile-menu_bottom-menu-item']}>
                  <Link
                    href={item.link}
                    onClick={closeMenu}
                    className={styles['mobile-menu_bottom-menu-link']}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <div className={classNames(styles['mobile-menu_action'], 'd-f ai-fs')}>
            <LangDropdown />
            <button
              type='button'
              onClick={onToggleOpenSetting}
              className={classNames(styles['mobile-menu_action-btn'])}
            >
              <Icon
                name="eye"
                width={20}
                height={20}
                viewBox="0 0 20 20"
              />
            </button>
          </div>
          {(activeTab?.whiteBtn || activeTab?.blueBtn) && (
            <div className={classNames(styles['mobile-menu_button'], 'd-f ai-c')}>
              {activeTab?.whiteBtn && (
                <Link
                  href={activeTab.whiteBtn.link}
                  onClick={onCloseMenu}
                  className={classNames('btn bg-white b-with-icon')}
                >
                  <Icon
                    name="bolt"
                    width={11}
                    height={15}
                  />
                  {activeTab.whiteBtn.name}
                </Link>
              )}
              {activeTab?.blueBtn && (
                <Link
                  href={activeTab.blueBtn.link}
                  onClick={onCloseMenu}
                  className={'btn'}
                >
                  {activeTab.blueBtn.name}
                </Link>
              )}
            </div>
          )}
        </>
      }
    </nav>
  )
}

HeaderMobileMenu.propTypes = {
  closeMenu: PropTypes.func,
  handlerClick: PropTypes.func,
  bottomMenu: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    name: PropTypes.string
  })),
  items: PropTypes.arrayOf(PropTypes.shape({
    whiteBtn: PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string
    }),
    blueBtn: PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string
    }),
    name: PropTypes.string,
    link: PropTypes.string,
    isOpenNewTab: PropTypes.any,
    banner: PropTypes.object,
    children: PropTypes.array
  }))
}
