'use client'
import { useMemo, useEffect, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import Banner from '@/app/components/Banner'
import Link from '@/app/components/Ui/Link'

import styles from './DropdownMenu.module.scss'

const SubMenu = ({ items = [], handlerClick }) => {
  if (!Array.isArray(items)) {
    return null
  }
  return (
    <ul className={styles['dropdown-submenu']}>
      {(items).map((item, index) => (
        <li
          key={index}
          className={classNames(styles['dropdown-submenu_item'])}
        >
          <Link
            href={item.link}
            isOpenNewTab={item.isOpenNewTab}
            onClick={handlerClick}
            className={styles['dropdown-submenu_link']}
          >
            <span className={styles['dropdown-submenu_text']}>
              {item.name}
            </span>
            {item.label &&
              <>
                &nbsp;
                <span className={styles['dropdown-submenu_label']}>
                  {item.label}
                </span>
              </>
            }
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default function DropdownMenu({ items = [], view = 'rows', banner, handlerClick }) {
  const isColumnView = useMemo(() => view === 'column', [view])

  const [activeMenuKey, setActiveMenuKey] = useState()
  const [activeSubMenu, setActiveSubMenu] = useState()

  useEffect(() => {
    if (isColumnView) {
      setActiveMenuKey(items?.[0]?.name)
      setActiveSubMenu(items?.[0]?.children)
    }
  }, [items, isColumnView])

  const onActiveMenuKey = (item) => {
    if (isColumnView) {
      setActiveSubMenu(item?.children || null)
      setActiveMenuKey(item?.name)
    }
  }

  const onClickLink = () => {
    if (typeof handlerClick === 'function') {
      handlerClick()
    }
  }

  return (
    <div className={classNames(
      styles['dropdown-menu'],
      styles[`b-view-${view}`],
      {
        [styles['b-has-banner']]: Boolean(banner),
      }
    )}>
      <div className={classNames(styles['dropdown-menu_wrapper'], 'd-f ai-fs')}>
        <div className={classNames(styles['dropdown-menu_content'], 'd-f ai-fs')}>
          <ul className={classNames(styles['dropdown-menu_list'], 'd-f ai-fs fw-w')}>
            {(items).map((item, index) => (
              <li
                key={index}
                className={classNames(styles['dropdown-menu_item'])}
              >
                {item.link ? (
                  <Link
                    href={item.link}
                    isOpenNewTab={item.isOpenNewTab}
                    onMouseEnter={() => onActiveMenuKey(item)}
                    onClick={onClickLink}
                    className={classNames(
                      styles['dropdown-menu_link'],
                      'd-f ai-c b-image-filter dropdown-menu_link',
                      {
                        [styles['b-focus']]: activeMenuKey === item.name
                      }
                    )}
                  >
                    {item.icon &&
                      <img
                        src={item.icon}
                        alt='icon'
                      />
                    }
                    {item.name}
                  </Link>
                ) : (
                  <div
                    onMouseEnter={() => onActiveMenuKey(item)}
                    className={classNames(
                      styles['dropdown-menu_link'],
                      'd-f ai-c b-image-filter dropdown-menu_link',
                      {
                        [styles['b-focus']]: activeMenuKey === item.name
                      }
                    )}
                  >
                    {item.icon &&
                      <img
                        src={item.icon}
                        alt='icon'
                      />
                    }
                    {item.name}
                  </div>
                )}
                {!isColumnView && item.children && item.children?.length &&
                  <SubMenu
                    items={item.children}
                    handlerClick={onClickLink}
                  />
                }
              </li>
            ))}
          </ul>
          {isColumnView && (
            <CSSTransition
              in={!!activeSubMenu}
              timeout={400}
              unmountOnExit
              classNames="fade"
            >
              <SubMenu items={activeSubMenu} handlerClick={onClickLink} />
            </CSSTransition>
          )}
        </div>
        {banner && <Banner data={banner} onClickLink={onClickLink} />}
      </div>
    </div>
  )
}

DropdownMenu.propTypes = {
  view: PropTypes.oneOf(['column', 'rows']),
  banner: PropTypes.object,
  handlerClick: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
    isOpenNewTab: PropTypes.any,
    icon: PropTypes.string,
    children: PropTypes.array
  }))
}
